import * as React from "react";
import { graphql, Link, StaticQuery } from "gatsby";
import { navigate } from "gatsby";
import FaMapMarker from "../../../images/faMapMarker";
import FaBack from "../../../images/faback";

function ToursList() {
  return (
    <StaticQuery
      query={graphql`
        query TourListings {
          allGraphCmsPageTours {
            edges {
              node {
                id
                pageTitle
                pageHeaderText {
                  html
                  markdown
                  raw
                  text
                }
                headerImage {
                  id
                  imageAltText
                  imageTitleText
                  galleryImage {
                    gatsbyImageData(quality: 60)
                    url
                  }
                }
              }
            }
          }

          allGraphCmsItemTours {
            edges {
              node {
                id
                tourTitle
                tourStatus
                tourPrice
                tourLocation
                slug
                createdAt
                tourDescription {
                  html
                  text
                  raw
                }
                tourImage {
                  id
                  imageAltText
                  imageTitleText
                  galleryImage {
                    gatsbyImageData(quality: 60)
                    url
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
       
        const sortedTours = [...data.allGraphCmsItemTours.edges].sort(
          (a, b) => Date.parse(b.node.createdAt) - Date.parse(a.node.createdAt)
        );

        return (
          <div className="hotel-listings">
            <div className="back-main">
              <a id="primary-button" onClick={() => navigate(-1)}>
                <FaBack />
              </a>
            </div>

            <div className="header">
              {data.allGraphCmsPageTours.edges.map(({ node: hotel }) => (
                <div className="header-image" key={hotel.id}>
                  <div className="header-overlay"></div>
                  <img
                    src={hotel.headerImage?.galleryImage?.url}
                    alt={hotel.headerImage?.imageAltText || "Tour Header"}
                  />
                  <div className="header-text">
                    <h1>{hotel.pageTitle}</h1>
                  </div>
                </div>
              ))}
            </div>

            <div className="listings">
              <div className="listings-content">
                {/* Desktop Tour Listings */}
                <div className="featured-listings desktop">
                  {sortedTours.map(({ node: blockMap }) => (
                    <div className="data-wrapper" key={blockMap.id}>
                      <Link to={`/${blockMap.slug}`}>
                        <div className="item-box">
                          <div id="image-field">
                            <img
                              src={blockMap.tourImage?.galleryImage?.url}
                              alt={blockMap.tourImage?.imageAltText || "Tour Image"}
                            />
                          </div>
                          <div className="text-box">
                            <span className="title">
                              <h2>{blockMap.tourTitle}</h2>
                            </span>
                            <div id="price">
                              <p>{blockMap.tourPrice}</p>
                            </div>
                            <div className="primary-info">
                              <span className="location">
                                <FaMapMarker />
                                {blockMap.tourLocation}
                                <a href="#">View Tour</a>
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>

                {/* Mobile Tour Listings */}
                <div className="featured-listings mobile">
                  {sortedTours.map(({ node: blockMap }) => (
                    <div className="data-wrapper" key={blockMap.id}>
                      <Link to={`/${blockMap.slug}`}>
                        <div className="item-box">
                          <ul className="mobile-content">
                            <li>
                              <div id="image-field">
                                <img
                                  src={blockMap.tourImage?.galleryImage?.url}
                                  alt={blockMap.tourImage?.imageAltText || "Tour Image"}
                                />
                              </div>
                            </li>
                            <li>
                              <div className="text-box">
                                <span className="title">{blockMap.tourTitle}</span>
                                <div id="price">
                                  <p>{blockMap.tourPrice}</p>
                                </div>
                                <div className="primary-info">
                                  <span className="location">
                                    <FaMapMarker />
                                    {blockMap.tourLocation}
                                    <a href="#">View Tour</a>
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default ToursList;
